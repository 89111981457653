export default {
  data () {
    return {
      prevRoute: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    backOrClose () {
      if (this.prevRoute && this.prevRoute.path !== '/') {
        this.$router.back()
      } else {
        window.close()
      }
    }
  }
}
