<template>
  <div>
    <template v-if="$apollo.loading && !refreshing">
      <div>読み込み中</div>
    </template>
    <template v-else>
      <!-- cancel wait -->
      <div class="d-flex" v-if="$route.path !== '/invitations-and-coupons-create'">
        <div
          class="
            t-calendar-item__title
            d-flex
            justify-space-between
            align-start
          "
        >
          <div @click="showCount = !showCount" class="cursor-pointer">
            <v-icon v-if="showCount">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
            枠数表示
          </div>
          <div class="d-flex flex-column">
            <template v-if="showCount">
              <div
                style="font-size: 12px"
                v-for="inv in invPercentages"
                :key="inv.nameAbbr"
                :style="$getColorAndBackground(inv.color, true)"
              >
                {{ inv.nameAbbr }} {{ inv.ratio | percent }}
              </div>
            </template>
          </div>
        </div>
        <div
          class="t-calendar-item__data-item"
          v-for="date in dateRange"
          :key="date"
        >
          <div class="d-flex flex-column" v-if="showCount">
            <div
              style="font-size: 12px"
              v-for="(inv, ind) in inventoryByDate[date].inventoryCountArray"
              :key="ind"
            >
              <span
                :style="$getColorAndBackground(inv.color, true) + ' padding: 0 5px;'"
                >{{ inv.count }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="t-calendar-item__title">&nbsp;</div>
        <div class="d-flex room-and-booking-container">
          <div
            class="
              t-calendar-item__data-item
              room-and-booking-area
              d-flex
              flex-column
            "
            v-for="date in dateRange"
            :key="date"
          >
            <template v-for="(inv, ind) in inventoryByDate[date].inventories">
              <booking-and-rooms
                :key="ind"
                :inventory="inv"
                :hiddenRoomIds="hiddenRoomIds"
                @inventoryClick="(payload) => $emit('inventoryClick', payload)"
                v-bind="{ mode, isInventorySelected, isInventoryValid }"
              />
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { getDateRangeArray } from '@/utils/dateUtil'
import BookingAndRooms from './BookingAndRooms.vue'

export default {
  components: {
    BookingAndRooms
  },
  props: {
    fromDate: String,
    toDate: String,
    completed: Function,
    roomType: Object,
    refreshCount: Number,
    mode: String,
    isInventorySelected: Function,
    isInventoryValid: Function
  },
  data () {
    return {
      inventoriesOrg: [],
      refreshing: false,
      showCount: false,
      rooms: [],
    }
  },
  computed: {
    inventories () {
      // we will filter out the bookings that belong to an unlisted room.
      return this.inventoriesOrg.map((inv) => {
        const displayedBookings = inv.bookings.filter((b) => !this.hiddenRoomIds.includes(b.room.id));
        const hiddenCount = inv.bookings.length - displayedBookings.length;
        inv.rooms -= hiddenCount;
        inv.bookings = displayedBookings;

        return inv;
      })
    },
    dateRange () {
      return getDateRangeArray(this.fromDate, this.toDate)
    },
    roomTypeId () {
      return this.roomType.id
    },
    inventoryByDate () {
      const inventoryByDate = this.dateRange.reduce((result, date) => {
        result[date] = {
          stayDate: date,
          inventories: []
        }
        return result
      }, {})
      this.inventories.forEach((item) => {
        if (inventoryByDate[item.stayDate]) {
          inventoryByDate[item.stayDate].inventories.push(item)
        }
      })
      for (const v of Object.values(inventoryByDate)) {
        v.inventories.sort((a, b) => a.inventoryTypeId - b.inventoryTypeId)
        v.inventoryCountArray = this.invCountsToArray(
          this.countInventories(v.inventories)
        )
      }

      return inventoryByDate
    },
    inventoryCounts () {
      const counts = this.countInventories(this.inventories)
      return counts
    },
    invPercentages () {
      const total = this.inventoryCounts.total
      const arr = this.invCountsToArray(this.inventoryCounts)
      for (const inv of arr) {
        inv.ratio = inv.count / total
      }

      return arr
    },
    hiddenRoomIds () {
      return this.rooms.filter((r) => !r.isListed).map((r) => r.id)
    },
  },
  methods: {
    async refresh (v) {
      this.refreshing = true
      try {
        await this.$apollo.queries.inventoriesOrg.refetch()
      } finally {
        this.refreshing = false
      }
    },
    countInventories (inventories) {
      const invCount = this.$store.getters.normalInventoryTypes.reduce(
        (result, it) => {
          result[it.id] = 0
          return result
        },
        {}
      )
      invCount.ext = 0
      invCount.total = 0
      inventories.forEach((item) => {
        if (this.$store.getters.isExternalInventoryType(item.inventoryTypeId)) {
          invCount.ext += item.rooms
        } else {
          invCount[item.inventoryTypeId] += item.rooms
        }
        invCount.total += item.rooms
      })

      return invCount
    },
    invCountsToArray (invCounts) {
      return this.$store.getters.inventoryCountsToArray(invCounts)
    },
    invCountsToArrayWithExternal (invCounts) {
      const result = this.$store.getters.inventoryTypes.map((it) => ({
        ...it,
        count: invCounts[it.id]
      }))
      return result
    }
  },
  watch: {
    inventoryCounts (counts) {
      this.completed({ counts })
    },
    async refreshCount () {
      await this.refresh()
    }
  },
  apollo: {
    inventoriesOrg: {
      query: gql`
        query (
          $roomTypeId: Int!
          $fromDate: DateString!
          $toDate: DateString!
        ) {
          inventoryList(
            dateFrom: $fromDate
            dateTo: $toDate
            roomTypes: [$roomTypeId]
          ) {
            roomTypeId
            rooms # この枠（inventory type)で登録されている部屋数。この数分色のついた四角が出る
            inventoryTypeId
            stayDate #宿泊日
            bookings {
              # array. この日この枠に当てはまるBooking 順番に rooms分出した四角の中に部屋名を入れていく. rooms < bookings.lengthの場合はオーバーブッキングなので部屋名を赤く表示する。
              room {
                id
                name
              } # 部屋ID / 部屋名 # もし部屋IDが重複していたらオーバーブッキングなので赤く表示する
              blockRoom # trueはブロック予約なので黒で表示する
            }
          }
        }
      `,
      update: (data) => data.inventoryList,
      variables () {
        return {
          roomTypeId: this.roomTypeId,
          fromDate: this.fromDate,
          toDate: this.toDate
        }
      },
      fetchPolicy: 'no-cache'
    },
    rooms: {
      query: gql`
        query (
          $roomTypeId: Int!
        ) {
          roomList(
            roomTypeId: $roomTypeId
          ) {
            id
            name
            isListed
          }
        }
      `,
      update: (data) => data.roomList,
      variables () {
        return {
          roomTypeId: this.roomTypeId,
        }
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.room-and-booking-container {
  background-color: #eee;
  align-items: start;
}
.room-and-booking-area {
  padding: 0 !important;
  font-size: 12px;
  &:empty {
    display: none;
  }
}
.parentBlock ~ .t-calendar-item__data-item .is-selected {
  background-color: #f00;
}
.cursor-pointer {
  color: #757575;
}
</style>
