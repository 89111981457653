<template>
  <div>
    <!-- :client-or-member-id="id" -->
    <reservation-calendar
      @close="backOrClose"
      @next="next"
    />
  </div>
</template>

<script>
import ReservationCalendar from './ReservationCalendar'
import backOrClose from '@/mixins/backOrClose'

export default {
  name: 'ReservationCalendarPage',
  mixins: [backOrClose],
  components: { ReservationCalendar },
  // computed: {
  //   id () {
  //     return this.$route.query.id
  //   }
  // },
  methods: {
    next (payload) {
      // the payload has the following props
      // clientId: Int!
      // roomTypeId: Int!
      // contractId: Int
      // bookingTypeId: Int
      // checkInDate: DateString!
      // checkOutDate: DateString!
      // days: {stayDate: DateString!; inventoryTypeId: Int!}[]
      // bookingPlanId: Int
      if (JSON.parse(localStorage.getItem('statusSetCalenDar'))) {
        this.$emit('close')
      } else {
        this.$router.push('/booking/new-booking?clientId=' + this.$route.query.id)
      }
      localStorage.setItem('statusSetCalenDar', JSON.stringify(false))
      // console.log(121212, payload)
    }
  }
}
</script>
