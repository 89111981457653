<template>
  <div>
    <div class="header-main">
      <h3 class="font-weight-bold py-4 page-title-main">
        {{ $t("reservationCalendar.title") }}
      </h3>
    </div>
    <div v-if="$apollo.queries.client.loading">読み込み中...</div>
    <div v-else class="px-main">
      <div class="d-flex align-center flex-wrap justify-space-between mt-4">
        <div class="main-calendar_l pr-3">
          <div
            class="
              modal-custom_block
              d-inline-flex
              align-center
              mb-4
              justify-space-between
              pt-2
              pb-3
            "
          >
            <h3 class="text-red mr-4">
              {{ $t("reservationCalendar.select") }}
            </h3>
            <div class="mr-4">
              <p class="modal-custom_txt mb-1">
                {{ $t("reservationCalendar.checkIn") }}
              </p>
              <p class="mb-0 fs-10">
                <span v-if="checkInDate"
                  >{{ checkInDate | jaLongDate }}から{{ days }}泊</span
                >
                <span v-else
                  >利用する枠を選択して下さい {{ lastClickedInventory }}</span
                >
              </p>
            </div>
            <div class="mr-4">
              <p class="modal-custom_txt mb-1">
                {{ $t("reservationCalendar.facility") }}
              </p>
              <p class="mb-0 fs-12">
                {{ selectedRoomType.facilityName || "選択して下さい" }}
              </p>
            </div>
            <div class="mr-1">
              <p class="modal-custom_txt mb-1">
                {{ $t("reservationCalendar.typeRoom") }}
              </p>
              <p class="mb-0 fs-12">
                {{ selectedRoomType.roomTypeName || "選択して下さい" }}
              </p>
            </div>
          </div>
        </div>
        <div class="main-calendar_r">
          <div class="modal-custom_block d-flex align-center mb-4 pt-3 pb-1">
            <v-btn v-if="$route.path === '/invitations-and-coupons-create'" color="error" class="btn-close mr-2" @click="closeDialog()">
              {{ $t("buttons.close") }}
            </v-btn>
            <v-btn v-else color="error" class="btn-close mr-2" href="javascript:window.open('','_self').close();">
              {{ $t("buttons.close") }}
            </v-btn>
            <v-btn
              :disabled="
                !checkPerUser || Object.keys(this.selectedDays).length === 0
              "
              color="primary"
              v-if="$route.query.type === 'reservation-without-customer-ID'"
              @click="goNewReservation()"
              >保存</v-btn
            >
            <v-btn
              @click="save()"
              :disabled="
                !checkPerUser || Object.keys(this.selectedDays).length === 0
              "
              color="primary"
              v-else-if="$route.path === '/invitations-and-coupons-create'"
              >保存</v-btn
            >
            <v-btn
              @click="goToBlock()"
              :disabled="
                !checkPerUser || Object.keys(this.selectedDays).length === 0
              "
              color="primary"
              v-else
              >保存</v-btn
            >
          </div>
        </div>
      </div>

      <facility-room-type-calendar ref="calendar" hideCancelWait height="800px" :defaultValues="dataCreateCoupon" hideMwCol>
        <!-- facility,
          mwCalendarCols, -->
        <template
          v-slot:default="{
            facility,
            fromDate,
            toDate,
            roomType,
            completed,
            refreshCount,
            dateRange,
            dateColWidth,
          }"
        >
          <div class="d-flex calendar-block_tt" v-if="$route.path !== '/invitations-and-coupons-create'">
            <div class="t-calendar-item__title">
              <div
                @click="togglePriceClosed(roomType.id)"
                class="d-flex align-center cursor-pointer"
              >
                <v-icon v-if="!isPriceClosed[roomType.id]"
                  >mdi-chevron-down</v-icon
                >
                <v-icon v-else>mdi-chevron-up</v-icon>
                <div>料金表示</div>
              </div>
            </div>
            <div
              class="t-calendar-item__data-item"
              v-for="date in dateRange"
              :key="date"
            >
              &nbsp;
            </div>
          </div>
          <fees-and-points-room-type
            v-if="isPriceClosed[roomType.id]"
            :facility="facility"
            :fromDate="fromDate"
            :toDate="toDate"
            :roomType="roomType"
            :isShowAccomList="true"
            :isShowBookingList="true"
            :mwWeekCalendarList="[]"
            forReservation
            :ref="feesAndPointKey(roomType.id)"
            :dateColWidth="dateColWidth"
          />
          <frame-setting-calendar-room-type
            :refreshCount="refreshCount"
            :fromDate="fromDate"
            :toDate="toDate"
            :roomType="roomType"
            :completed="completed"
            @inventoryClick="inventoryClick"
            :isInventorySelected="isInventorySelected"
            :isInventoryValid="isInventoryValid"
            mode="booking"
          />
        </template>
      </facility-room-type-calendar>
    </div>
  </div>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import FacilityRoomTypeCalendar from '@/components/FacilityRoomTypeCalendar'
import {
  addDays,
  getNumberOfDaysBetween,
  getDateRangeArray
} from '@/utils/dateUtil'
import FrameSettingCalendarRoomType from '@/views/dashboard/frameSetting/frameSettingCalendar/frameSettingCalendarRoomType'
import FeesAndPointsRoomType from '@/views/dashboard/feesPoints/feesAndPoints/components/FeesAndPointsRoomType'
import {
  contractIsForNewBooking,
  getNewBookingName,
  isPointBasedProduct,
  ProductTypeIdToPlanType
} from '@/constants/productTypeId'
import gql from 'graphql-tag'
import { BookingTypeIds } from '@/constants/bookingType'
import { mapGetters, mapMutations } from 'vuex'
import { isForbiddenInventoryType, forbiddenInventoryTypeAlert } from '@/utils/inventory'

const TRIAL_CONTRACT_ID = -1
const FakeContractIdToBookingTypeId = {
  [TRIAL_CONTRACT_ID]: BookingTypeIds.TRIAL
}

export default {
  name: 'ReservationCalendar',
  components: {
    FacilityRoomTypeCalendar,
    FrameSettingCalendarRoomType,
    FeesAndPointsRoomType
  },
  props: {
    clientOrMemberId: {
      type: [String, Number],
      required: false
    },
    dataCalendar: Object,
    dataCreateCoupon: {
      type: Object,
      default: () => {}
    }
  },

  async mounted () {
    await this.$store.dispatch('loadInventoryTypes')
    this.getData()
    this.getSelectDays()
  },
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      facilityId: null,
      contractId:
        this.$route.query.fromPage === 'crm'
          ? parseInt(this.$route.query.contractId)
          : undefined,
      adultLists: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      numberOfAdults: 1,
      childLists: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      numberOfChildren: 0,
      bedShareList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      numberOfBedShare: 0,
      bookingPlanId: null,
      isPriceClosed: {},
      selectedDays: {},
      facilities: [],
      pricesByDay: [],
      client: null,
      night: null,
      selectList: []
    }
  },
  computed: {
    ...mapGetters(['lastClickedInventory']),
    contractListWithOther () {
      console.log(
        this.contractList.concat([
          {
            id: TRIAL_CONTRACT_ID,
            newBookingName: '体験予約',
            bookingTypeId: FakeContractIdToBookingTypeId[TRIAL_CONTRACT_ID]
          }
        ])
      )
      return this.contractList.concat([
        {
          id: TRIAL_CONTRACT_ID,
          newBookingName: '体験予約',
          bookingTypeId: FakeContractIdToBookingTypeId[TRIAL_CONTRACT_ID]
        }
      ])
    },
    realContractInfo () {
      return {
        contractId: this.contractId > 0 ? this.contractId : null, // null for non-contract based booking such as trial booking
        bookingTypeId: FakeContractIdToBookingTypeId[this.contractId]
      }
    },
    contract () {
      if (this.contractId) {
        return this.contractList.find((c) => c.id === this.contractId)
      }
      return undefined
    },
    productTypeId () {
      return this.contract?.productType?.id
    },
    pointBasedProduct () {
      if (this.productTypeId) {
        return isPointBasedProduct(this.productTypeId)
      }
      return false
    },
    ready () {
      return this.checkInDate && typeof this.contractId !== 'undefined'
    },
    checkInDate () {
      return Object.keys(this.selectedDays).sort().shift()
    },
    checkOutDate () {
      return addDays(this.checkInDate, this.days)
    },
    bookDays () {
      return [
        {
          stayDate: '2021-01-01',
          inventoryTypeId: 1
        }
      ]
    },
    days () {
      return Object.keys(this.selectedDays).length
    },
    roomTypeId () {
      const day = this.selectedDays[this.checkInDate]
      if (day) return day.roomTypeId
      return null
    },
    selectedRoomType () {
      const roomTypeId = this.roomTypeId
      if (roomTypeId) {
        for (const facility of this.facilities) {
          for (const roomType of facility.roomTypes) {
            if (roomType.id === roomTypeId) {
              localStorage.setItem('facilityId', JSON.stringify(facility.id))
              return {
                facilityid: facility.id,
                roomTypeid: this.roomTypeId,
                facilityName: facility.name,
                roomTypeName: roomType.name
              }
            }
          }
        }
      }
      return {
        facilityid: undefined,
        roomTypeid: undefined,
        facilityName: undefined,
        roomTypeName: undefined
      }
    },
    planLists () {
      if (!this.roomTypeId || !this.productTypeId) return []

      const ref = this.$refs[this.feesAndPointKey(this.roomTypeId)]
      const plans = ref.getBookingPlans()
      const planType = ProductTypeIdToPlanType[this.productTypeId]

      if (planType) {
        return plans.filter(
          (item) => item.targetBookingType === planType && item.enabled
        )
      } else {
        return []
      }
    },
    totalAccommodation () {
      return this.pricesByDay.reduce(
        (sum, day) => sum + day.accommodationFee,
        0
      )
    },
    totalPrice () {
      let total = 0
      if (!this.pointBasedProduct) {
        total += this.totalAccommodation
      }
      return total
    },
    totalPoint () {
      let total = 0
      if (this.pointBasedProduct) {
        total += this.totalAccommodation
      }
      return total
    },
    selectedInventories () {
      return Object.keys(this.selectedDays)
        .sort()
        .map((stayDate) => {
          return {
            // stayDate,
            inventoryTypeId: this.selectedDays[stayDate].inventoryTypeId
          }
        })
    },
    contractList () {
      if (!this.client?.contracts) return []

      return this.client.contracts
        .filter((c) => contractIsForNewBooking(c.productType.id))
        .map((c) => {
          c.newBookingName =
            getNewBookingName(c.productType.id) + ` (契約ID: ${c.id})`
          return c
        })
        .sort((a, b) => a.id - b.id)
    },
    isBlock () {
      return this.$route.query.type === 'block'
    }
  },
  watch: {
    planLists () {
      this.bookingPlanId = null
    }
  },
  methods: {
    ...mapMutations(['setSelectedDays']),
    onSaveCommon ({ ok }) {
      const msg = this.$store.getters.getInventoryWarning(Object.values(this.selectedDays))
      if (msg) {
        this.$confirm({
          title: '枠属性確認',
          message: msg,
          ok
        })
      } else {
        ok()
      }
    },
    goNewReservation () {
      this.onSaveCommon({
        ok: () => {
          localStorage.setItem(
            'selectedReservationRooms',
            JSON.stringify(this.selectedDays)
          )
          this.$router.push(`/reservation/new-reservation?checkInDate=${this.checkInDate}&night=${this.night}&facility=${this.selectedRoomType.facilityName}&facilityID=${this.selectedRoomType.facilityid}&roomTypeId=${this.selectedRoomType.roomTypeid}&roomTypeName=${this.selectedRoomType.roomTypeName}`)
        }
      })
    },

    goToBlock () {
      this.onSaveCommon({
        ok: () => {
          localStorage.setItem(
            'selectedReservationRooms',
            JSON.stringify(this.selectedDays)
          )
          this.$router.push(
            `/booking/block?checkInDate=${this.checkInDate}&night=${this.night}&facility=${this.selectedRoomType.facilityName}&facilityID=${this.selectedRoomType.facilityid}&roomTypeId=${this.selectedRoomType.roomTypeid}&roomTypeName=${this.selectedRoomType.roomTypeName}`
          )
        }
      })
    },

    getData () {
      if (this.dataCalendar) {
        this.contractId = this.dataCalendar.contractId
        this.numberOfAdults = this.dataCalendar.numberOfAdults
        this.numberOfChildren = this.dataCalendar.numberOfChildren
        this.numberOfBedShare = this.dataCalendar.numberOfBedShare
        this.bookingPlanId = this.dataCalendar.bookingPlanId
        this.facilityId = this.dataCalendar.facilityId
      }
    },

    togglePriceClosed (roomTypeId) {
      this.$set(
        this.isPriceClosed,
        roomTypeId,
        !this.isPriceClosed[roomTypeId]
      )
    },
    getDayObj ({ inventory, ind }) {
      return {
        stayDate: inventory.stayDate,
        inventoryTypeId: inventory.inventoryTypeId,
        roomTypeId: inventory.roomTypeId,
        ind
      }
    },
    inventoryClick (payload) {
      const aDay = this.getDayObj(payload)

      if (isForbiddenInventoryType(aDay.inventoryTypeId)) {
        this.$alert(forbiddenInventoryTypeAlert())
        return
      }

      // cleanup if there are other room types selected
      if (
        Object.values(this.selectedDays).some(
          (day) => day.roomTypeId !== aDay.roomTypeId
        )
      ) {
        this.selectedDays = {}
      }
      // if not the first cell to click, it must be
      // 1. next to a already selected day
      // 2. or updating already selected day
      if (
        Object.keys(this.selectedDays).length &&
        !this.selectedDays[aDay.stayDate]
      ) {
        var before = addDays(aDay.stayDate, -1)
        var after = addDays(aDay.stayDate, 1)
        if (this.$route.path === '/invitations-and-coupons-create') {
          before = addDays(aDay.stayDate, 0)
          after = addDays(aDay.stayDate, 0)
        }
        if ([before, after].every((date) => !this.selectedDays[date])) {
          return false // don't select
        }
      }
      const current = this.selectedDays[aDay.stayDate]
      if (!current) {
        this.$set(this.selectedDays, aDay.stayDate, aDay)
      } else {
        if (
          current.inventoryTypeId === aDay.inventoryTypeId &&
          current.ind === aDay.ind
        ) {
          // and the days after this until checkout should be removed as well
          const range = getDateRangeArray(
            aDay.stayDate,
            this.checkOutDate,
            true
          )
          for (const date of range) {
            this.$delete(this.selectedDays, date)
          }
        } else {
          this.$set(this.selectedDays, aDay.stayDate, aDay)
        }
      }
      this.night = Object.keys(this.selectedDays).length
      localStorage.setItem('selectedDays', JSON.stringify(this.selectedDays))
    },

    getSelectDays () {
      if (JSON.parse(localStorage.getItem('statusSetCalenDar'))) {
        this.selectedDays = JSON.parse(localStorage.getItem('selectedDays'))
      }
    },

    isInventorySelected (payload) {
      const aDay = this.getDayObj(payload)
      const current = this.selectedDays[aDay.stayDate]
      if (!current) return false
      return (
        current.roomTypeId === aDay.roomTypeId &&
        current.ind === aDay.ind &&
        current.inventoryTypeId === aDay.inventoryTypeId
      )
    },

    isInventoryValid (payload) {
      const keys = Object.keys(this.selectedDays)
      if (keys.length === 0) return false
      const aDay = this.getDayObj(payload)
      // get min and max dates in selected days
      const firstDate = keys.reduce(function (a, b) {
        return a < b ? a : b
      })
      const lastDate = keys.reduce(function (a, b) {
        return a > b ? a : b
      })
      // if room type id or inventory type id is not same as day in the selected days
      if (
        this.selectedDays[firstDate].roomTypeId !== aDay.roomTypeId ||
        this.selectedDays[firstDate].inventoryTypeId !== aDay.inventoryTypeId
      ) {
        return false
      }
      if (this.$route.path === '/invitations-and-coupons-create' && ((aDay.stayDate < firstDate &&
          Math.abs(getNumberOfDaysBetween(aDay.stayDate, firstDate)) > 0) ||
        (aDay.stayDate > lastDate &&
          Math.abs(getNumberOfDaysBetween(aDay.stayDate, lastDate)) > 0))) {
        return true
      } else if (
        (aDay.stayDate < firstDate &&
          Math.abs(getNumberOfDaysBetween(aDay.stayDate, firstDate)) > 1) ||
        (aDay.stayDate > lastDate &&
          Math.abs(getNumberOfDaysBetween(aDay.stayDate, lastDate)) > 1)
      ) {
        return true
      }
      return false
    },

    feesAndPointKey (roomTypeId) {
      return `feesAndPointKey${roomTypeId}`
    },
    close () {
      this.$emit('close')
    },
    async next () {
      const data = {
        roomTypeId: this.roomTypeId,
        contractId: this.realContractInfo.contractId,
        bookingTypeId: this.realContractInfo.bookingTypeId,
        checkInDate: this.checkInDate,
        checkOutDate: this.checkOutDate,
        days: this.selectedInventories,
        bookingPlanId: this.bookingPlanId,
        numberOfAdults: this.numberOfAdults,
        numberOfChildren: this.numberOfChildren,
        numberOfBedShare: this.numberOfBedShare,
        totalPrice: this.totalPrice,
        totalPoint: this.totalPoint
      }
      await localStorage.setItem('dataCreateBooking', JSON.stringify(data))

      this.$emit('next', {
        clientId: this.client?.id,
        roomTypeId: this.roomTypeId,
        contractId: this.realContractInfo.contractId,
        bookingTypeId: this.realContractInfo.bookingTypeId,
        checkInDate: this.checkInDate,
        checkOutDate: this.checkOutDate,
        days: this.selectedInventories,
        bookingPlanId: this.bookingPlanId,
        numberOfAdults: this.numberOfAdults,
        numberOfChildren: this.numberOfChildren,
        numberOfBedShare: this.numberOfBedShare,
        totalPrice: this.totalPrice,
        totalPoint: this.totalPoint,
        clientOrMemberId: this.clientOrMemberId
      })
    },

    save () {
      this.onSaveCommon({
        ok: () => {
          this.setSelectedDays(this.selectedDays)
          this.$emit('close')
        }
      })
    },

    closeDialog () {
      this.$emit('close')
    }
  },
  apollo: {
    client: {
      query: gql`
        query ($id: String!) {
          getClientByIdOrMemberId(id: $id) {
            id
            contracts {
              id
              canCheckInUntil
              canCheckOutUntil
              productType {
                id
                name
              }
              endDate
            }
          }
        }
      `,
      variables () {
        return { id: `${this.clientOrMemberId}` }
      },
      skip () {
        return !this.clientOrMemberId
      },
      update: (data) => data.getClientByIdOrMemberId
    },
    facilities: {
      query: gql`
        query {
          facilityList (loadRoomType: true) {
            id
            name
            roomTypes {
              id
              name
            }
          }
        }
      `,
      update: (data) => data.facilityList
    },
    pricesByDay: {
      query: gql`
        query (
          $contractId: Int
          $bookingTypeId: Int
          $roomTypeId: Int!
          $checkInDate: DateString!
          $checkOutDate: DateString!
          $numberOfAdults: Int!
          $numberOfChildren: Int!
          $numberOfBedShare: Int!
          $bookingPlanId: Int
        ) {
          calculateAccommodationPrice(
            contractId: $contractId
            bookingTypeId: $bookingTypeId
            roomTypeId: $roomTypeId
            checkInDate: $checkInDate
            checkOutDate: $checkOutDate
            numberOfAdults: $numberOfAdults
            numberOfChildren: $numberOfChildren
            numberOfBedShare: $numberOfBedShare
            bookingPlanId: $bookingPlanId
          ) {
            pricesByDay {
              date
              accommodationFee
            }
          }
        }
      `,
      variables () {
        return {
          contractId: this.realContractInfo.contractId,
          bookingTypeId: this.realContractInfo.bookingTypeId,
          roomTypeId: this.roomTypeId,
          checkInDate: this.checkInDate,
          checkOutDate: this.checkOutDate,
          numberOfAdults: this.numberOfAdults,
          numberOfChildren: this.numberOfChildren,
          numberOfBedShare: this.numberOfBedShare,
          bookingPlanId: this.bookingPlanId
        }
      },
      skip () {
        return !this.ready
      },
      update: (data) => data.calculateAccommodationPrice.pricesByDay
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-custom_select {
    label {
      font-size: 13px !important;
    }
    .v-select__selections input {
      font-size: 12px !important;
    }
  }
}
.select-people {
  width: 30px !important;
}
.select-child {
  width: 73px !important;
}
.contract-select {
  width: 100px;
}
.plan-select {
  width: 100px;
}
.text-red {
  color: #ff0000;
  font-size: 14px;
}
.text-blue {
  color: #2a7edc;
}
.modal-custom_block {
  color: #000;
}
.modal-custom_txt {
  font-size: 10px;
  white-space: nowrap;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.main-calendar {
  &_l {
    @media (min-width: 1600px) {
      flex: 0 0 33.3333333333%;
      max-width: 70%;
      .modal-custom_block {
        width: 100%;
      }
    }
  }
  &_r {
    @media (min-width: 1600px) {
      .modal-custom_block {
        width: 100%;
      }
    }
  }
}
.cursor-pointer {
  height: 20px;
}
.calendar-block_tt {
  .t-calendar-item__data-item {
    border-top: 1px solid $calendar-border-color;
  }
}
</style>
